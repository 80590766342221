<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    permanent
    left
    width="200"
    color="#FDFDFD"
    floating
  >
    <v-app-bar color="#FDFDFD" dense outlined light elevation="0" class="mt-3">
      <img class="brand_logo" src="@/assets/aprotime-logo.svg" />
      <p class="brand_text">Aprotime</p>
    </v-app-bar>
    <!-- <v-divider></v-divider> -->

    <v-list v-if="userDetails.roles.has_all_access" subheader flat nav dense>
      <v-list-item-group
        v-model="selectedItem"
        active-class="link"
        color="#000"
        class="my-8"
      >
        <div v-for="(header, index) in filteredLinks" :key="index">
          <v-subheader
            @click="toggleHeader(header.index)"
            class="category_text d-flex justify-space-between align-center"
            color="black"
          >
            <div class="d-flex align-center">
              <v-icon
                class="me-2"
                :style="{
                  color: 'black',
                  borderRadius: '50%',
                }"
              >
                {{
                  header.header === "Time Tracking"
                    ? "mdi-clock"
                    : header.header === "Payments"
                    ? "mdi-invoice-text"
                    : header.header === "Management"
                    ? "mdi-cog"
                    : "mdi-note-text"
                }}
              </v-icon>

              <span class="header-text">{{ header.header }}</span>
            </div>
            <div style="margin-bottom: 0.5px">
              <v-icon size="20" color="black" background-color="primary">
                {{
                  isHeaderOpen(header.index)
                    ? "mdi-chevron-up"
                    : "mdi-chevron-down"
                }}
              </v-icon>
            </div>
          </v-subheader>
          <template v-if="isHeaderOpen(header.index)">
            <v-list-item
              v-for="item in header.items"
              :key="item.title"
              router
              :to="item.route"
            >
              <v-list-item-content>
                <v-list-item-title class="nav_text title-with-margin">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
      </v-list-item-group>
    </v-list>
    <v-list v-else subheader flat nav dense>
      <v-list-item-group
        v-model="selectedItem"
        active-class="link"
        color="#000"
        class="my-8"
      >
        <div v-for="(header, index) in filteredLinks" :key="index">
          <v-subheader
            @click="toggleHeader(header.index)"
            class="category_text d-flex justify-space-between"
            color="black"
          >
            <div class="d-flex align-center">
              <v-icon
                class="me-2"
                :style="{
                  color: 'black',
                  borderRadius: '50%',
                }"
              >
                {{
                  header.header === "Time Tracking"
                    ? "mdi-clock"
                    : header.header === "Payments"
                    ? "mdi-invoice-text"
                    : header.header === "Management"
                    ? "mdi-cog"
                    : "mdi-note-text"
                }}
              </v-icon>

              <span class="header-text">{{ header.header }}</span>
            </div>

            <v-icon color="black">
              {{
                isHeaderOpen(header.index)
                  ? "mdi-chevron-up"
                  : "mdi-chevron-down"
              }}
            </v-icon>
          </v-subheader>
          <template v-if="isHeaderOpen(header.index)">
            <v-list-item
              v-for="item in header.items"
              :key="item.title"
              router
              :to="item.route"
            >
              <v-list-item-content>
                <v-list-item-title class="nav_text title-with-margin">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
      </v-list-item-group>
    </v-list>

    <v-footer color="#FDFDFD" v-if="!isThirdHeaderOpen" padless class="px-10">
      <v-row class="">
        <span class="me-2 nav_text">
          {{ userDetails.first_name }} {{ userDetails.last_name }}
        </span>
        <v-avatar rounded size="30" color="#D9D9D9">
          <v-icon size="20" color="#8C8C8C" dark>mdi-account</v-icon>
        </v-avatar>
        <p
          :style="{
            color: 'black',
            fontSize: '12px',
          }"
        >
          Version 0.01
        </p>
      </v-row>
    </v-footer>
    <div v-if="isThirdHeaderOpen" padless class="px-10">
      <v-row class="">
        <span class="me-2 nav_text">
          {{ userDetails.first_name }} {{ userDetails.last_name }}
        </span>
        <v-avatar rounded size="30" color="#D9D9D9">
          <v-icon size="20" color="#8C8C8C" dark>mdi-account</v-icon>
        </v-avatar>
        <p
          :style="{
            color: 'black',
            fontSize: '12px',
          }"
        >
          Version 0.01
        </p>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideNav",

  data: () => ({
    drawer: true,
    selectedItem: 0,
    openHeaders: [],

    links: [
      { header: "Time Tracking" },
      {
        title: "Time Entry",
        icon: "mdi-view-dashboard-outline",
        route: "/auth/time-entry",
      },
      {
        title: "Calendar",
        icon: "mdi-list-box-outline",
        route: "/auth/calendar",
      },
      { header: "Payments" },
      {
        title: "Invoices",
        icon: "mdi-receipt-text-outline",
        route: "/auth/invoices",
      },
      { header: "Entities" },
      { title: "Clients", icon: "mdi-account-outline", route: "/auth/clients" },
      {
        title: "Projects",
        icon: "mdi-note-text-outline",
        route: "/auth/projects",
      },
      {
        title: "Employees",
        icon: "mdi-account-multiple-outline",
        route: "/auth/employees",
      },
      {
        title: "Ranks",
        icon: "mdi-account-multiple-outline",
        route: "/auth/ranks",
      },
      {
        title: "Departments",
        icon: "mdi-alpha-d-circle-outline",
        route: "/auth/departments",
      },
      {
        title: "Offices",
        icon: "mdi-office-building-outline",
        route: "/auth/offices",
      },
      {
        title: "Profit Centre",
        icon: "mdi-hand-coin-outline",
        route: "/auth/profit-centre",
      },
      {
        title: "Activities",
        icon: "mdi-playlist-check",
        route: "/auth/activity",
      },
      { header: "Management" },
      { title: "Settings", icon: "mdi-cog-outline", route: "/auth/settings" },
    ],
  }),

  computed: {
    userDetails() {
      return this.$store.state.Auth.user;
    },

    filteredLinks() {
      const headers = [];
      let currentHeader = null;
      this.links.forEach((item, index) => {
        if (item.header) {
          currentHeader = { header: item.header, index, items: [] };
          headers.push(currentHeader);
        } else if (currentHeader) {
          currentHeader.items.push(item);
        }
      });

      return headers;
    },

    isThirdHeaderOpen() {
      // Find the index of the "Entities" header in the filteredLinks array
      const entitiesHeader = this.filteredLinks.find(
        (header) => header.header === "Entities"
      );

      // Return true if "Entities" is open, false otherwise
      return entitiesHeader ? this.isHeaderOpen(entitiesHeader.index) : false;
    },
  },

  methods: {
    toggleHeader(index) {
      if (this.openHeaders.includes(index)) {
        this.openHeaders = this.openHeaders.filter((i) => i !== index);
      } else {
        this.openHeaders.push(index);
      }
    },
    isHeaderOpen(index) {
      return this.openHeaders.includes(index);
    },

    // getHeaderIcon(header) {
    //   switch (header) {
    //     case "Time Tracking":
    //       return "mdi-clock-outline";
    //     case "Payments":
    //       return "mdi-receipt-text-outline";
    //     case "Management":
    //       return "mdi-cog-outline";
    //     default:
    //       return "";
    //   }
    // },
  },
};
</script>

<style scoped>
.category_text {
  margin-top: 10px;
  cursor: pointer;
  color: black;
  font-size: 14px;
  width: 100%;
}
.header-text {
  color: black;
}
.title-with-margin {
  margin-left: 40px;
  margin-top: -10px;
}
.brand_logo {
  width: 26px;
}
.brand_text {
  font-size: 18px;
  font-weight: 700;
  color: black;
  margin-left: 5px;
  margin-top: 17px;
}
.v-footer {
  width: 100%;
  position: absolute;
  bottom: 30px;
  left: 0;
}
.v-avatar {
  margin-top: -4px;
}
.nav_text {
  font-size: 14px;
  color: black;
}
</style>

<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar
      v-model="snackbar"
      :top="isTop"
      :bottom="isBottom"
      :right="isRight"
      :left="isLeft"
      :color="snackbarColor"
      text
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbarColor"
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import EventBus from "@/common/EventBus.js";

export default {
  name: "App",
  data: () => ({
    snackbar: false,
    snackbarMessage: "",
    snackbarColor: "",
    snackbarPosition: "bottom-right",
  }),
  computed: {
    isTop() {
      return this.snackbarPosition.includes("top");
    },
    isBottom() {
      return this.snackbarPosition.includes("bottom");
    },
    isRight() {
      return this.snackbarPosition.includes("right");
    },
    isLeft() {
      return this.snackbarPosition.includes("left");
    },
  },
  methods: {
    showSnackbar(data) {
      this.snackbar = true;
      this.snackbarColor = data.color;
      this.snackbarMessage = data.message;
      if (data.position) this.snackbarPosition = data.position;
    },

    handleShowSnackbar(data) {
      this.showSnackbar(data);
    },
  },
  mounted() {
    EventBus.on("show-snackbar", this.handleShowSnackbar);
  },
  beforeDestroy() {
    EventBus.remove("show-snackbar", this.handleShowSnackbar);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

#app {
  font-family: "DM Sans", system-ui;
}
</style>

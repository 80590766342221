<template>
  <div class="mt-3">
    <v-app-bar class="nav_bar" color="#fff" dense outlined light elevation="0">
      <span class="page_title">{{ currentTitle }}</span>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  components: {},
  data: () => ({
    show: false,
    commentDrawer: false,
    comments: 2,
    email: "",
    password: "",
    items: [
      { title: "Home", icon: "mdi-home-city" },
      { title: "My Account", icon: "mdi-account" },
      { title: "Users", icon: "mdi-account-group-outline" },
    ],
  }),
  methods: {},
  computed: {
    currentTitle() {
      console.log("Current route name:", this.$route.name);
      return this.$route.name || "Dashboard";
    },
  },
};
</script>

<style scoped>
.logo_banner {
  width: 11rem;
}
.page_title {
  font-size: 18px;
  font-weight: 600;
  color: black;
}
.some-style >>> .v-input__slot::before {
  border-style: none !important;
}
.comment_title {
  font-size: 14px;
  font-weight: 400;
}
.comment_date {
  font-size: 13px;
  color: #666666;
}
.comment_text {
  font-size: 14px;
  font-weight: 400;
}
.nav_text {
  font-size: 12px;
}
</style>
